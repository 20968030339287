import styled from 'styled-components';
import { colors } from '@/styles';

export const Form = styled.form<{}>`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 40px;
`;

export const FieldSet = styled.div.attrs((props) => ({}))`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

export const FormActions = styled.div.attrs((props) => ({}))``;

export const ErrorMessage = styled.div`
  background: ${colors.semantic.negative.light};
  color: ${colors.semantic.negative.dark};
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 25px;
`;

export const SuccessMessage = styled.div`
  background: ${colors.semantic.positive.light};
  color: ${colors.semantic.positive.dark};
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 25px;
`;
